import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import "./style.scss";
import logoImg from "../../assets/images/logo.svg";
import pfLogoImg from "../../assets/images/pflogo.svg";

const Footer = props => {
  const [status, setStatus] = useState({ open: false });
  return (
    <div className="footer">
      <div className="footer__top">
        {props.noRef !== true && (
          <Container className="referrence__container">
            <div
              className={status.open ? "referrence  active" : "referrence"}
              onClick={() => {
                return setStatus({ open: !status.open });
              }}
            >
              <span />
              References
            </div>
            <Collapse in={status.open}>
              <div>
                <p>
                  1. Santarpia L, <em>et al. Expert Opin Ther Targets</em>{" "}
                  2012;16:103–19. 2. Liu F, <em>et al. Acta Pharm Sin B</em>{" "}
                  2018;8: 552–62. 3. Van Cutsem E, <em>et al. Ann Oncol</em>{" "}
                  2016;27:1386–422. 4. Kafatos G, <em>et al. Biomark</em> Med
                  2017;11: 751–60. 5. Cremolini C, Loupakis F, Antoniotti C,{" "}
                  <em>et al</em>. FOLFOXIRI plus bevacizumab versus FOLFIRI plus
                  bevacizumab as first-line treatment of patients with
                  metastatic colorectal cancer: updated overall survival and
                  molecular subgroup analyses of the open-label, phase 3 TRIBE
                  study <em>Lancet Oncol</em>. 2015;16(13):1306–15. 6. Stintzing
                  S, <em>et al</em>. Eur J Cancer 2017;79:50–60. 7. Peeters M,{" "}
                  <em>et al. J Clin Oncol</em> 2014;32:A3568.
                  {/* 1. Van Cutsem E, <i>et al. Ann Oncol</i> 2016;27:1386–422. 2.
								Kafatos G, <i>et al. Biomark Med </i>2017;11:751–60. 3.
								Cremolini C, Loupakis F, Antoniotti C, <i>et al</i>. FOLFOXIRI
								plus bevacizumab versus FOLFIRI plus bevacizumab as first-line
								treatment of patients with metastatic colorectal cancer: updated
								overall survival and molecular subgroup analyses of the
								open-label, phase 3 TRIBE study. <i>Lancet Oncol</i> 2015;16(13):1306–15. 4. Stintzing S, <i>et al. Eur J Cancer</i> 2017;79:50–60. 5. Peeters M,{" "} <i>et al. J Clin Oncol</i> 2014;32:A3568. 6. Santarpia L,{" "} <i>et al. Expert Opin Ther Targets</i> 2012;16:103–19. 7. Liu F, <i>et al. Acta Pharm Sin B</i> 2018;8:552–62. */}
                </p>
              </div>
            </Collapse>
          </Container>
        )}
      </div>
      <div className="footer__bottom">
        <Container>
          <Row>
            <Col sm={12} className="logo">
              <a href="/">
                <img src={logoImg} alt="Test mCRC mutations" />
                {/* test<span className="logo__mcrc">mCRC</span>
								<br />
								<span className="logo__mutations">mutations</span> */}
              </a>
              <div className="pf-details">
                <img src={pfLogoImg} alt="Pierre Fabre" />
                <span className="code">CRC-IWEB-WO-000028</span>
              </div>
            </Col>
            <Col md={5}>
              <div>&copy; 2022 testmCRC mutations. All rights reserved</div>
            </Col>
            <Col md={7} className="footer__navigation">
              <div className="footer__links">
                {/*<Link to="/cookies">Cookie Preferences</Link>*/}
                <a
                    href="#"
                    class="ot-sdk-show-settings"
                    rel="noopener noreferrer"
                >
                  Cookies Settings
                </a>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/legal">Legal</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default Footer;
