import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Hero from "../../Components/Hero";
import Content from "../../Components/Content";
import GoogleTagManager from "../../Components/GoogleTagManager";
import "../../main.scss";
import { DEFAULT_GTM_ID } from "../../utils/analyticsUtils";
import DocumentMeta from 'react-document-meta';

const Home = ({ location, globalState }) => {
  const directory = location.pathname;

  const meta = {
    title: 'Test Mutation Bowel & Colorectal Cancer | Diagnostic test for colon cancer | Test Braf v600e Mutation',
    description: 'Discover all informations about the importance of mutation testing of the colorectal cancer. MAPK pathway, Outcomes and Biomaker testing.',
    meta: {
      name: {
        title: 'Test Mutation Bowel & Colorectal Cancer | Diagnostic test for colon cancer | Test Braf v600e Mutation'
      }
    }
  };

  return (
    <div>
      <DocumentMeta {...meta} />
      <GoogleTagManager isEnabled={globalState.isGAOn} gtmId={DEFAULT_GTM_ID} />
      <Header location={directory} />
      <Hero />
      <div className="content__container">
        <Content />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
