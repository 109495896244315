import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import logoImg from "../../assets/images/logo.svg";
import "./style.scss";
import parse from "html-react-parser";

const Header = props => {
  const [fix, setFix] = useState("");
  const [fixmobile, setFixmobile] = useState("");
  const [navstatus, setNavstatus] = useState("");

  window.addEventListener("scroll", () => {
    const cookieBar = document.querySelector(".cookie__content");
    const cookieHeight = cookieBar ? cookieBar.clientHeight : null;

    let top =
      document.querySelector(".header__top").clientHeight + cookieHeight;
    if (window.scrollY >= top) {
      setFix("fixed-top");
    } else {
      setFix("");
    }
  });

  const toggleNav = () => {
    setNavstatus(navstatus === "" ? "active" : "active fadein");
    setFixmobile(fix === "" && navstatus === "" ? "fixed__mobile" : "");
  };

  useEffect(() => {
    let timeout = "";
    if (navstatus === "active fadein") {
      timeout = setTimeout(() => {
        setNavstatus("");
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [navstatus]);

  useEffect(() => {
    setFixmobile("");
    setNavstatus("");
    window.scrollTo(0, 0);
  }, [props.location]);

  return (
    <div className={fixmobile}>
      <div className="header__top">
        <Container>
          <div>This website is intended for healthcare professionals only</div>
        </Container>
      </div>
      <div
        className="header__placeholder"
        style={{
          display: fix === "fixed-top" ? "block" : "none"
        }}
      />
      <div className={`navbar__wrapper ${fix}`}>
        <div>
          <Navbar variant="dark" expand="lg" className={navstatus}>
            <Container>
              <Navbar.Brand href="/" className="logo">
                {props.location === "/" &&
                <h1>
                  <img src={logoImg} alt="Test Mutation Colorectal Cancer"/>
                </h1>
                }
                {props.location !== "/" &&
                <img src={logoImg} alt="Test mCRC mutations" />
                }
                {/* test<span className="logo__mcrc">mCRC</span>
								<br />
								<span className="logo__mutations">mutations</span> */}
              </Navbar.Brand>
              <div className="navbar-toggler" onClick={toggleNav}>
                <span className="navbar-toggler-icon" />
              </div>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="">
                  <Link
                    className={props.location === "/" ? "nav-active" : ""}
                    to="/"
                  >
                    Home
                  </Link>
                  <Link
                    className={props.location === "/about" ? "nav-active" : ""}
                    to="/about"
                  >
                    About
                  </Link>
                  <Link
                    className={
                      props.location === "/outcomes" ? "nav-active" : ""
                    }
                    to="/outcomes"
                  >
                    Outcomes
                  </Link>
                  <Link
                    className={
                      props.location === "/testing" ? "nav-active" : ""
                    }
                    to="/testing"
                  >
                    Testing
                  </Link>
                </Nav>
                {/*<Link className="nav__button" to="/signup">*/}
                {/*  Sign up for updates*/}
                {/*</Link>*/}
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    </div>
  );
};

export default Header;
