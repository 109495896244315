import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "@babel/polyfill";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import * as serviceWorker from "./serviceWorker";

import Home from "./Pages/Home/";
import About from "./Pages/About/";
import Outcomes from "./Pages/Outcomes/";
import Testing from "./Pages/Testing/";
import Standard from "./Pages/Standard/";
import { getCookieValue } from "./utils/cookieUtils";
import CookiePreferences from "./Pages/CookiePreferences";
import Legal from "./Pages/Legal";
import SignUp from "./Pages/SignUp";
import Privacy from "./Pages/Privacy";
//import Cookie from "./Components/Cookie";
import DocumentMeta from 'react-document-meta';

const renderWithProps = (component, props) => {
  return React.createElement(component, {
    ...props
  });
};

class App extends React.Component {
  constructor() {
    super();
    this.state = { isGAOn: true };
    this.setGlobalState = this.setGlobalState.bind(this);
  }

  setGlobalState(newState) {
    this.setState({ ...newState });
  }

  render() {
    const meta = {
      title: 'Look for the dangerous drivers in metastatic colorectal cancer',
      description: 'Patients with colorectal cancer with certain mutations have poor outcomes. The ESMO guidelines recommend testing patients for dangerous drivers at diagnosis.',
      meta: {
        name: {
          title: 'react,meta,document,html,tags'
        }
      }
    };

    return (
        <DocumentMeta {...meta}>
      <PFsite globalState={this.state} setGlobalState={this.setGlobalState} />
        </DocumentMeta>
    );
  }
}

const PFsite = ({ globalState, setGlobalState }) => {
  return (
    <React.Fragment>
      <Router>
        {/*<Cookie globalState={globalState} setGlobalState={setGlobalState} />*/}
        <Switch>
          <Route
            exact
            path="/"
            render={props => {
              return renderWithProps(Home, {
                ...props,
                globalState,
                setGlobalState
              });
            }}
          />
          <Route
            exact
            path="/about"
            render={props =>
              renderWithProps(About, {
                ...props,
                globalState,
                setGlobalState
              })
            }
          />
          <Route
            exact
            path="/outcomes"
            render={props =>
              renderWithProps(Outcomes, {
                ...props,
                globalState,
                setGlobalState
              })
            }
          />
          <Route
            exact
            path="/testing"
            render={props =>
              renderWithProps(Testing, {
                ...props,
                globalState,
                setGlobalState
              })
            }
          />
          <Route
            exact
            path="/legal"
            render={props =>
              renderWithProps(Legal, {
                ...props,
                globalState,
                setGlobalState
              })
            }
          />
          <Route
            exact
            path="/privacy-policy"
            render={props =>
              renderWithProps(Privacy, {
                ...props,
                globalState,
                setGlobalState
              })
            }
          />
          <Route
            exact
            path="/cookies"
            render={props =>
              renderWithProps(CookiePreferences, {
                ...props,
                globalState,
                setGlobalState
              })
            }
          />
          <Route
            exact
            path="/signup"
            render={props =>
              renderWithProps(SignUp, {
                ...props,
                globalState,
                setGlobalState
              })
            }
          />
          <Route
            render={props =>
              renderWithProps(Standard, {
                ...props,
                globalState,
                setGlobalState
              })
            }
          />
        </Switch>
      </Router>
    </React.Fragment>
    // inside the render method where you want to include the tag manager
  );
};
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
