import React from "react";
import "./style.scss";
import { Container, Row, Col, Card } from "react-bootstrap";
import icon1 from "../../assets/images/card-icon-1.svg";
import icon2 from "../../assets/images/card-icon-2.svg";
import icon3 from "../../assets/images/card-icon-3.svg";
import icon4 from "../../assets/images/card-icon-4.svg";

function Cards() {
  return (
    <div className='cards__block'>
      <Container>
        <p className='text-center padding-bottom'>
          Knowing the mutation status of a patient with mCRC can help to:
          <sup>3</sup>
        </p>
        <Row>
          <Col md={6} xl={3}>
            <Card>
              <div className='card__image-wrapper'>
                <Card.Img variant='top' src={icon1} />
              </div>
              <Card.Body className='text-center'>
                <Card.Text>
                  Stratify patients according to their molecular profile
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} xl={3}>
            <Card className='text-center'>
              <div className='card__image-wrapper'>
                <Card.Img variant='top' src={icon4} />
              </div>
              <Card.Body>
                <Card.Text>
                  Gain a deeper knowledge of the impact and clinical relevance
                  of mutations
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} xl={3}>
            <Card className='text-center'>
              <div className='card__image-wrapper'>
                <Card.Img variant='top' src={icon3} />
              </div>
              <Card.Body>
                <Card.Text>
                  Promote enrolment of patients into clinical trials
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} xl={3}>
            <Card className='text-center'>
              <div className='card__image-wrapper'>
                <Card.Img variant='top' src={icon2} />
              </div>
              <Card.Body>
                <Card.Text>Improve outcomes for patients with mCRC</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Cards;
