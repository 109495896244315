import React from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import SecondaryHero from "../../Components/SecondaryHero";
import { Container, Button } from "react-bootstrap";
import Aboutimg1 from "../../assets/images/about-img1.svg";
import Aboutimg1mobile from "../../assets/images/about-img1-mobile.svg";
import Aboutimg2 from "../../assets/images/about-img2.svg";
import Aboutimg2mobile from "../../assets/images/about-img2-mobile.svg";
import "../../main.scss";
import GoogleTagManager from "../../Components/GoogleTagManager";
import { DEFAULT_GTM_ID } from "../../utils/analyticsUtils";
import DocumentMeta from 'react-document-meta';

const About = ({ location, globalState }) => {
  const directory = location.pathname;

  const meta = {
    title: 'Braf MAPK signaling pathway | RAS mutations in colorectal cancer | ',
    description: 'Key drivers for the aberrant MAPK signalling are mutations in the downstream pathway components, RAS or BRAF1,2',
    meta: {
      name: {
        title: 'Braf MAPK signaling pathway | RAS mutations in colorectal cancer | '
      }
    }
  };

  return (
    <div>
      <DocumentMeta {...meta} />
      <GoogleTagManager isEnabled={globalState.isGAOn} gtmId={DEFAULT_GTM_ID} />
      <Header location={directory} />
      <SecondaryHero page={directory} />
      <Container>
        <div className='content__wrapper'>
          <p className='highlight'>
            Key drivers for the aberrant MAPK signalling are mutations in the
            downstream pathway components, <i>RAS</i> or <i>BRAF</i>
            <sup>1,2</sup>
          </p>
        </div>
        <hr />
        <div className='content__wrapper'>
          <p>
            The MAPK pathway is an important regulator of cell function,
            including cell growth, proliferation
            <br className='desktop-only' /> and cell differentiation.
            <sup>2</sup>
          </p>
        </div>

        <div className='content__img d-none d-md-block'>
          <img src={Aboutimg1} alt='' />
        </div>

        <div className='content__img d-sm-block d-md-none'>
          <img src={Aboutimg1mobile} alt='' />
        </div>

        <p className='note'>Created from: Refs. 1,2</p>

        <div className='content__wrapper'>
          <p>
            In mCRC, this pathway becomes dysregulated by a number of mechanisms
            — the most frequent of which is mutations in <i>RAS</i> and{" "}
            <i>BRAF</i>. As a result, the pathway may become overactive,
            triggering aberrant cell proliferation, differentiation, migration,
            survival and angiogenesis.<sup>1,2</sup>
          </p>
        </div>
        <hr />
        <div className='content__wrapper'>
          <p className='highlight'>
            Around 50% of your patients with mCRC will have either a <i>RAS</i>{" "}
            or a <i>BRAF</i> mutation<sup>3&ndash;4</sup>
          </p>
          <div className='content__img d-none d-md-block'>
            <img src={Aboutimg2} alt='' />
          </div>
          <div className='content__img d-sm-block d-md-none'>
            <img src={Aboutimg2mobile} alt='' />
          </div>
          <p className='note'>
            Adapted from: Refs. 3,4
            <br />
            *This is the maximum; values may vary up to this.
          </p>
        </div>
        <hr />
        <div className='content__wrapper button__wrapper'>
          <h2>
            Patients with mCRC harbouring a <i>RAS</i> or <i>BRAF</i>
            &nbsp;mutation have poorer outcomes than those with wild-type
            tumours<sup>5&ndash;7</sup>
          </h2>
          <Link to='/outcomes'>
            <Button>
              <div>
                Discover the danger of <i>RAS</i> and <br />
                <i>BRAF</i>&nbsp;&nbsp;mutations <span />
              </div>
            </Button>
          </Link>
        </div>
        <div className='content__wrapper'>
          <p className='acronym'>
            EGFR, epidermal growth factor receptor; ERK, extracellular
            signal-regulated kinase; MAPK, mitogen-activated protein kinase;
            mCRC, metastatic <span className='nowrap'>colorectal cancer.</span>
          </p>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default About;
