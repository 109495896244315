import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import SecondaryHero from "../../Components/SecondaryHero";
import Cards from "../../Components/Cards";
import GoogleTagManager from "../../Components/GoogleTagManager";
import "../../main.scss";
import { DEFAULT_GTM_ID } from "../../utils/analyticsUtils";
import DocumentMeta from 'react-document-meta';

const Testing = ({ location, globalState }) => {
  const directory = location.pathname;

  const meta = {
    title: 'Colon Cancer Biomarker Testing | Genetic testing and molecular biomarker mCRC | RAS & Braf testing',
    description: 'According to the ESMO guidelines, \'Tumour BRAF mutation status should be assessed alongside the assessment of tumour RAS mutational status\'3',
    meta: {
      name: {
        title: 'Colon Cancer Biomarker Testing | Genetic testing and molecular biomarker mCRC | RAS & Braf testing'
      }
    }
  };

  return (
    <div>
      <DocumentMeta {...meta} />
      <GoogleTagManager isEnabled={globalState.isGAOn} gtmId={DEFAULT_GTM_ID} />
      <Header location={directory} />
      <SecondaryHero page={directory} />
      <Container>
        <div className='content__wrapper'>
          <p className='highlight'>
            According to the ESMO guidelines,{" "}
            <i>
              'Tumour BRAF mutation status should be assessed alongside the
              assessment of tumour RAS mutational status'
            </i>
            <sup>3</sup>
          </p>
        </div>
        <hr />
      </Container>
      <Cards />
      <Container>
        <div className='content__wrapper'>
          <h2>
            All patients with mCRC should be tested for <i>RAS</i> and{" "}
            <i>BRAF</i> mutations at diagnosis to enable optimal diagnostic and
            prognostic decision-making<sup>3</sup>
          </h2>
        </div>
        <div className='testing__highlight'>
          <hr />
          <p>Test for mutations today</p>
          <hr />
        </div>
        <div className='content__wrapper testing__wrapper button__wrapper'>
          <p className='bold'>
            A selection of resources to download will be <i>coming soon!</i>
          </p>
          {/* <a
						href="mailto:test@yourcompany.com?bcc=test@yourcompany.com&subject=Sign%20up%20for%20updates&body=Sign%20up%20to%20be%20the%20first%20to%20hear%20more.%20By%20registering,%20you%20certify%20that%20you%20are%20a%20healthcare%20professional,%20indicate%20that%20you%20wish%20to%20receive%20information%20about%20Pierre%20Fabre%20and%20related%20disease%20education,%20and%20agree%20that%20Pierre%20Fabre%20can%20contact%20you%20directly%20by%20email%20or%20by%20other%20forms%20of%20communication.%0D%0A%0D%0A
						Intended%20for%20healthcare%20professionals%20only%0D%0A
						Enter%20details%0D%0A
						First%20name:%0D%0A
						Surname:%0D%0A
						Speciality:%0D%0A%0D%0A
						By%20registering,%20Pierre%20Fabre%20and%20its%20agents%20can%20collect%20the%20information%20provided%20and%20contact%20you%20about%20Pierre%20Fabre%20and%20disease%20education.%20Pierre%20Fabre%20will%20use%20your%20information%20in%20accordance%20with%20its%20Privacy%20Policy,%20which%20can%20be%20found%20on%20our%20website.%0D%0A%0D%0AThe%20GDPR%20is%20a%20general%20regulation%20that%20applies%20to%20the%20collecting%20and%20processing%20of%20personal%20data%20of%20data%20subjects%20in%20the%20EU%20by%20all%20kinds%20of%20entities%20in%20all%20activities.%20The%20GDPR%20replaces%20the%20EU%20Data%20Protection%20Directive%2095/46/CE.%20In%20the%20event%20that%20your%20personal%20data%20is%20covered%20by%20the%20EU%20General%20Data%20Protection%20Regulation%20(EU)%202016/679%20(“GDPR”),%20you%20have%20rights%20to%20your%20data.%20Provided%20your%20personal%20data%20is%20covered%20by%20GDPR,%20please%20note%20that%20you%20have%20the%20right%20to%20request%20from%20Pierre%20Fabre%20access%20to%20and%20rectification%20of%20your%20personal%20data%20as%20well%20as%20the%20right%20to%20data%20portability,%20if%20applicable,%20or%20erasure%20or%20restriction%20of%20processing%20of%20your%20personal%20data.%20For%20more%20information,%20email%20info@pierrefabre.com."
					>
						<Button>
							Register for updates here
							<span />
						</Button>
					</a> */}
        </div>
        <div className='content__wrapper'>
          <p className='acronym'>
            ESMO, European Society for Medical Oncology; mCRC, metastatic
            colorectal cancer.
          </p>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Testing;
