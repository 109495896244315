import React from "react";
import { Container } from "react-bootstrap";
import blocks from "./content";
import "./styles.scss";
import Header from "../../Components/Header";
import SecondaryHero from "../../Components/SecondaryHero";
import GoogleTagManager from "../../Components/GoogleTagManager";
import Footer from "../../Components/Footer";
import { DEFAULT_GTM_ID } from "../../utils/analyticsUtils";
import DocumentMeta from 'react-document-meta';

export default ({ location, globalState }) => {
  const directory = location.pathname;

  const meta = {
    title: 'Legal | Test mCRC Mutations',
    description: '',
    meta: {
      name: {
        title: 'Legal | Test mCRC Mutations'
      }
    }
  };

  return (
    <div className="legal">
      <DocumentMeta {...meta} />
      <GoogleTagManager isEnabled={globalState.isGAOn} gtmId={DEFAULT_GTM_ID} />
      <Header />
      <SecondaryHero page={directory} />
      <Container>
        <div className="legal__inner-container">
          {blocks.map((block, index) => {
            const { content, title } = block;
            return (
              <div className="legal__block" key={`legal-block-${index}`}>
                <div className="legal__block-title">{title}</div>
                <div
                  className="legal__block-content"
                  dangerouslySetInnerHTML={{ __html: content }}
                ></div>
              </div>
            );
          })}
        </div>
      </Container>
      <Footer />
    </div>
  );
};
