export default [
  {
    title: "Editor",
    content: `<p><b>Pierre Fabre Médicament</b> Société par Actions Simplifiées with a capital of 29 182 454 Euros, it’s registered office is located at 45 place Abel Gance 92100 Boulogne Billancourt, registered at RCS NANTERRE SIREN 326 118 502, with intracommunity VAT number FR 35 326 118 502.</p>
    <p>Acting on behalf of « PIERRE FABRE ONCOLOGY » department 45 place Abel Gance 92100 Boulogne Billancourt.</p>
    <p>Tel: + 01 49 10 80 00</p>`
  },
  {
    title: "Editorial Director",
    content: `<address>
      <p>Pierre Fabre Médicament</p>
      <p>45 place Abel Gance</p>
      <p>92100 Boulogne Billancourt</p>
      </address>`
  },
  {
    title: "Webmaster",
    content: `<p><a href="mailto:contact.pfo@pierre-fabre.com">contact.pfo@pierre-fabre.com</a></p>`
  },
  {
    title: "Host",
    content: `
    <p>CIS VALLEY</p>
    <p>Société Anonyme Simplifiée au capital de 2 500 000 Euros</p>
    <p>immatriculée au RCS de BORDEAUX sous le numéro B 410 100 747</p>
    <p>Chemin de l’Hermite BP 70081</p>
    <p>33523 BRUGES</p>
    <p>FRANCE</p>
    <p>The present general conditions determine the conditions of use of Pierre Fabre Medicament website <a href="https://www.testmcrcmutations.com" target="_blank">“www.testmcrcmutations.com”</a> which users are deemed tacitly to have accepted simply by visiting the site. If you do not wish to accept these conditions, we request that you leave the site at once. If you are legally a minor in the state in which you reside, you undertake to consult this site only in the presence of your parents or a tutor who must have previously accepted the present conditions. These conditions may be modified at any time without prior notice.</p>
    `
  },
  {
    title: "Aim of the site",
    content: `
    <p>This site has been created to provide information on mutational status of patients with mCRC. The content of this website shall not be considered as having contractual value.</p>
    <p>Public communication in regard of drugs is subject to specific regulation; the information provided on this site may not be construed as offering a medical opinion or considered as a substitute for consultation with a health professional.</p>
    <p>Equally, the information on this site may not be construed to recommend the use of drug products without the advice of a doctor.</p>`
  },
  {
    title: "Guarantee",
    content: `
    <p>Pierre Fabre Medicament undertakes to ensure that the content of this site complies with the legal provisions currently in force. They undertake especially not to distribute content that is in violation of the rights of third parties, as being of a violent, pornographic or defamatory character and not to distribute content that is illicit, and in particular racist, xenophobic, pedophiliac or otherwise detrimental to human dignity.</p>
    <p>However, Pierre Fabre Medicament does not guarantee that the information contained on this site is accurate, up to date or complete.</p>`
  },
  {
    title: "Liability",
    content: `
    <p>Pierre Fabre Medicament shall not be liable for:</p>
    <ul>
      <li>Damage or injury resulting directly or indirectly from the consultation of this site or from the use of information available on the site;</li>
      <li>Direct or indirect harm resulting from the use of the site, and especially for any adverse operating, financial or trading effects or any loss of programs or data in information systems, even where Pierre Fabre Medicament may have prior information as to the potential for such harm.</li>
      <li>Pierre Fabre Group shall assume no liability in relation to links with other sites, which are supplied for information purposes only. Any link from this site to another other site is considered to be accessed under the sole responsibility of the user.</li>
      <li>“Laboratoires Pierre Fabre” shall not be held responsible for the information available on these other sites and shall assume no liability in relation to their content.</li>
    </ul>
    <p>Pierre Fabre Medicament may not be held responsible for any hyperlinks that lead to this site. The creation by a third party of any hyperlink leading to this site or to any of the pages on this site shall require the prior agreement of “Laboratoires Pierre Fabre.”</p>
    <p>Pierre Fabre Medicament reserves the right to demand the removal of any such link.</p>
    <p>Pierre Fabre Medicament reserves the right, as considered necessary, to modify or suspend temporarily or permanently, all or part of this site, without prior notice or indemnity of any kind whatsoever. Pierre Fabre Medicament may under no circumstances be considered liable to the user or to any third party for any modification, interruption or suspension of this site.</p>`
  },
  {
    title: "Intellectual property rights",
    content: `
    <p>All the images and texts available on this site, together with all the product or brand names mentioned, are the exclusive property of Pierre Fabre Group or of their subsidiaries or are held by them under license.</p>
    <p>Information available on this site may only be downloaded, posted or printed for private use, that is to say for non-commercial purposes, without any modification being made to declarations regarding intellectual property rights and in taking care to reproduce the following statement on each copy made: “This document is derived from the Pierre Fabre Medicament website (http://www.testmcrcmutations.com). All rights of reproduction are reserved”.</p>
    <p>Using information from the website in any other manner than that indicated above is prohibited without the prior written authorization of “Laboratoires Pierre Fabre.”</p>`
  },
  {
    title: "Applicable law & jurisdiction",
    content: `
    <p>The present general conditions are subject to French law. Failing amicable settlement, the French courts shall have jurisdiction to settle any dispute relative to non-compliance with the present terms.</p>
    `
  }
];
