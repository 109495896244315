import React from "react";
import { Link } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import SecondaryHero from "../../Components/SecondaryHero";
import Outcomeimg1 from "../../assets/images/outcome-img1.svg";
import GoogleTagManager from "../../Components/GoogleTagManager";
import "../../main.scss";
import { DEFAULT_GTM_ID } from "../../utils/analyticsUtils";
import DocumentMeta from 'react-document-meta';

const Outcomes = ({ location, globalState }) => {
  const directory = location.pathname;

  const meta = {
    title: 'Braf & RAS Wild Type Tumours Outcomes | ESMO guidelines colorectal cancer',
    description: 'RAS- and, to a greater extent, BRAF-mutated tumours are associated with a poorer prognosis than RAS and BRAF wild-type tumours, based on retrospective analyses of clinical trial data5–7',
    meta: {
      name: {
        title: 'Braf & RAS Wild Type Tumours Outcomes | ESMO guidelines colorectal cancer'
      }
    }
  };

  return (
    <div>
      <DocumentMeta {...meta} />
      <GoogleTagManager isEnabled={globalState.isGAOn} gtmId={DEFAULT_GTM_ID} />
      <Header location={directory} />
      <SecondaryHero page={directory} />
      <Container>
        <div className='content__wrapper'>
          <p className='highlight padding-bottom'>
            <i>RAS</i>- and, to a greater extent,{" "}
            <span className='nowrap'>
              <i>BRAF</i>-mutated
            </span>{" "}
            tumours are associated with a poorer prognosis than <i>RAS</i> and{" "}
            <span className='nowrap'>
              <i>BRAF</i> wild-type
            </span>{" "}
            tumours, based on retrospective analyses of clinical trial data
            <sup>5&ndash;7</sup>
          </p>
          <p className='highlight'>
            Overall survival according to molecular subgroups in 357 patients
            with mCRC receiving first-line treatment<sup>5</sup>
          </p>
          <div className='image__wrapper'>
            <img src={Outcomeimg1} alt='' />
          </div>
          <p className='note alignleft'>Adapted from the TRIBE study, Ref 5.</p>
        </div>
        <hr />
        <div className='content__wrapper button__wrapper'>
          <h2>
            Knowing tumour <i>RAS</i> and <i>BRAF</i> mutation status enables
            optimal diagnostic and prognostic decision-making, therefore, ESMO
            guidelines recommend that all patients with mCRC should be tested{" "}
            <span className='nowrap'>
              at diagnosis<sup>1</sup>
            </span>
          </h2>
          <br />
          <Link to='/testing'>
            <Button>
              Knowing can make a difference
              <span />
            </Button>
          </Link>
        </div>
        <div className='content__wrapper'>
          <p className='acronym'>
            ESMO, European Society for Medical Oncology; mCRC, metastatic
            colorectal cancer.
          </p>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Outcomes;
