/* eslint-disable no-multi-str */
import React from "react";
import "./style.scss";
import { Container } from "react-bootstrap";
import parse from "html-react-parser";

const SecondaryHero = props => {
  let herocopy = "";
  switch (props.page) {
    case "/about":
      herocopy =
        "The MAPK pathway is the most frequently dysregulated signalling pathway linked to various cancers, such as colorectal cancer<sup>1,2</sup>";
      break;
    case "/outcomes":
      herocopy =
        'Patients with mCRC with <i>RAS</i>- or <br className="md-up" /><span className="nowrap"><i>BRAF</i>-mutated</span> tumours have poorer outcomes than those with wild-type tumours<sup>5&ndash;7</sup>';
      break;
    case "/testing":
      herocopy =
        "Biomarker testing should be carried out at the point of mCRC diagnosis<sup>3</sup>";
      break;
    case "/privacy-policy":
      herocopy = "Privacy and cookies policy";
      break;
    case "/legal":
      herocopy = "Legal notices";
      break;
    // case "/cookies":
    //   herocopy = "Cookie Preferences";
    //   break;
    // case "/signup":
    //   herocopy =
    //     "Sign up for updates and you’ll be the first to hear when more information is available.";
    //   break;
    default:
      herocopy = "Something went wrong!";
      break;
  }
  return (
    <div className='secondaryhero__block'>
      <div className='secondaryhero__block-bg' />
      <Container className='secondaryhero__header'>
        <div className='content__wrapper '>
          <h1>{parse(herocopy)}</h1>
        </div>
      </Container>
    </div>
  );
};

export default SecondaryHero;
