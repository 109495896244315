import React from "react";
import Header from "../../Components/Header";
import parse from "html-react-parser";
import Footer from "../../Components/Footer";
import SecondaryHero from "../../Components/SecondaryHero";
import { Link } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import GoogleTagManager from "../../Components/GoogleTagManager";
import "./style.scss";
import { DEFAULT_GTM_ID } from "../../utils/analyticsUtils";
import DocumentMeta from 'react-document-meta';

const Standard = ({ location, globalState, setGlobalState }) => {
  const directory = location.pathname;

  let title = "";
  let content = "";
  let statusClass = "d-none";

  switch (directory) {
    case "/privacy-policy":
      title = "Privacy policy";
      content = "<p>...</p>";
      break;
    case "/legal":
      title = "Legal page";
      content = "<p>...</p>";
      break;
    default:
      title =
        "We’re taking a closer look at our website’s DNA to find out what the problem is and how best to treat it.";
      content =
        "<p>Go to the home page to find out why you should do the same for your patients.</p>";
      statusClass = "d-block";
      break;
  }
  return (
    <div>
      <GoogleTagManager isEnabled={globalState.isGAOn} gtmId={DEFAULT_GTM_ID} />
      <div className="main__content">
        <div>
          <Header location={directory} />
          <SecondaryHero page={directory} />
          <Container>
            <div className="content__wrapper">
              <p className="highlight">{title}</p>
              <hr />
              {parse(content)}
              <Link className={statusClass} to="/">
                <Button>
                  Home
                  <span />
                </Button>
              </Link>
            </div>
          </Container>
        </div>
        {directory === "/privacy-policy" || directory === "/legal" ? (
          <Footer />
        ) : (
          <Footer noRef={true} />
        )}
      </div>
    </div>
  );
};

export default Standard;
