import React, { useEffect } from "react";
import EmbededForm from "../../Components/EmbededForm";
import GoogleTagManager from "../../Components/GoogleTagManager";
import Header from "../../Components/Header";
import SecondaryHero from "../../Components/SecondaryHero";
import { Container } from "react-bootstrap";
import { DEFAULT_GTM_ID } from "../../utils/analyticsUtils";
import Footer from "../../Components/Footer";
import useAvailable from "../../hooks/useAvailable";
import "./styles.scss";
import DocumentMeta from 'react-document-meta';
import { Redirect } from "react-router-dom";


export default function SignUp({ location, globalState }) {
  //const directory = location.pathname;
  //const form = useAvailable("#myform_OncologieInscription");

  // useEffect(() => {
  //   const onChange = e => {
  //     if (e.target.value) {
  //       // e.target.classList.add("not-empty");
  //       // document
  //       //   .querySelector(`label[for=${e.target.id}]`)
  //       //   .classList.add("is-hidden");
  //     } else {
  //       // e.target.classList.remove("not-empty");
  //       // document
  //       //   .querySelector(`label[for=${e.target.id}]`)
  //       //   .classList.remove("is-hidden");
  //     }
  //   };

  //   if (form) {
  //     form[0]
  //       .querySelectorAll(".form-row input, .form-row select")
  //       .forEach(input => {
  //         onChange({ target: input });
  //         input.addEventListener("input", onChange);
  //       });
  //   }
  //   return () => {
  //     if (form) {
  //       form[0]
  //         .querySelectorAll(".form-row input, .form-row select")
  //         .forEach(input => {
  //           input.removeEventListener("input", onChange);
  //         });
  //     }
  //   };
  // }, [form]);

  // const meta = {
  //   title: 'Sign-up | Test mCRC Mutations',
  //   description: '',
  //   meta: {
  //     name: {
  //       title: 'Sign-up | Test mCRC Mutations'
  //     }
  //   }
  // };
  //
  // return (
  //   <div>
  //     <DocumentMeta {...meta} />
  //     <div className='sign-up'>
  //       <GoogleTagManager
  //         isEnabled={globalState.isGAOn}
  //         gtmId={DEFAULT_GTM_ID}
  //       />
  //       <Header />
  //       <SecondaryHero page={directory} />
  //       <Container>
  //         <hr />
  //         <EmbededForm id='ac_InscriptionOncologie' scriptKey='Oncologie' />
  //       </Container>
  //     </div>
  //     <Footer noRef />
  //   </div>
  // );

  return <Redirect to={"/"} />
}
