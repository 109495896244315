import React from "react";
import Header from "../../Components/Header";
import GoogleTagManager from "../../Components/GoogleTagManager";
import SwitchButton from "../../Components/SwitchButton";
import SecondaryHero from "../../Components/SecondaryHero";
import { Container } from "react-bootstrap";
import "./styles.scss";
import Footer from "../../Components/Footer";
import { DEFAULT_GTM_ID } from "../../utils/analyticsUtils";
import DocumentMeta from 'react-document-meta';
import { Redirect } from "react-router-dom";

export default function CookiePreferences({
  globalState,
  setGlobalState,
  location
}) {
  // const directory = location.pathname;
  //
  // const meta = {
  //   title: 'Cookies | Test mCRC Mutations',
  //   description: '',
  //   meta: {
  //     name: {
  //       title: 'Cookies | Test mCRC Mutations'
  //     }
  //   }
  // };
  //
  // return (
  //   <div>
  //     <DocumentMeta {...meta} />
  //     <div className="cookie-preferences">
  //       <GoogleTagManager
  //         isEnabled={globalState.isGAOn}
  //         gtmId={DEFAULT_GTM_ID}
  //       />
  //       <Header />
  //       <SecondaryHero page={directory} />
  //       <section className="cookie-preferences__content">
  //         <Container>
  //           <hr />
  //           <div className="cookie-preferences__types">
  //             <div className="cookie-preferences__type-block">
  //               <div className="cookie-preferences__type-title-area">
  //                 <h3 className="cookie-preferences__type-title">
  //                   Performance Cookies
  //                 </h3>
  //                 <div className="cookie-preferences__type-switch">
  //                   <SwitchButton
  //                     isChecked={globalState.isGAOn}
  //                     onChange={isChecked => {
  //                       setGlobalState({ isGAOn: isChecked });
  //                     }}
  //                   />
  //                 </div>
  //               </div>
  //               <p className="cookie-preferences__type-copy">
  //                 These cookies are used to improve our Website, for example for
  //                 analytics that let us see how our Website is being used and
  //                 where to make improvements. These cookies are used to collect
  //                 information about how visitors use our Website. The
  //                 information is collected in an anonymous form and includes the
  //                 number of visitors, where visitors have come from to the
  //                 Websites and the pages they visited.
  //               </p>
  //             </div>
  //             <div className="cookie-preferences__type-block">
  //               <div className="cookie-preferences__type-title-area">
  //                 <h3 className="cookie-preferences__type-title">
  //                   Operating cookies
  //                 </h3>
  //                 <div className="cookie-preferences__type-switch">
  //                   <SwitchButton isChecked />
  //                 </div>
  //               </div>
  //               <p className="cookie-preferences__type-copy">
  //                 These cookies are necessary to ensure the optimal functioning
  //                 of the site. We record your visits and we offer you content
  //                 based on your navigation. This type of cookie is essential to
  //                 the proper functioning of this site.
  //               </p>
  //             </div>
  //           </div>
  //         </Container>
  //       </section>
  //     </div>
  //     <Footer noRef />
  //   </div>
  // );

  return <Redirect to={"/"} />
}
