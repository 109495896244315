import React from "react";
import gtmParts from "react-google-tag-manager";
import PropTypes from "prop-types";
import { setCookie, searchCookie } from "../../utils/cookieUtils";

class GoogleTagManager extends React.Component {
  componentDidMount() {
    if (this.props.isEnabled) {
      this.init();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isEnabled && !prevProps.isEnabled) {
      this.init();
    } else if (!this.props.isEnabled && prevProps.isEnabled) {
      this.removeCookies();
    }
  }

  removeCookies() {
    const domain = window.location.hostname;
    const analyticsCookie = searchCookie("UA-")[0];

    [domain, `.${domain}`].forEach(() => {
      setCookie("_ga", "", "0", domain);
      setCookie("_gid", "", "0", domain);
    });

    if (analyticsCookie) {
      setCookie(analyticsCookie, "", "", domain);
    }
  }

  init() {
    const dataLayerName = this.props.dataLayerName || "dataLayer";
    const scriptId = this.props.scriptId || "react-google-tag-manager-gtm";

    if (!window[dataLayerName]) {
      const gtmScriptNode = document.getElementById(scriptId);
      eval(gtmScriptNode.textContent);
    }
  }

  render() {
    const gtm = gtmParts({
      id: this.props.gtmId,
      dataLayerName: this.props.dataLayerName || "dataLayer",
      additionalEvents: this.props.additionalEvents || {},
      previewVariables: this.props.previewVariables || false
    });

    return this.props.isEnabled ? (
      <div>
        <div>{gtm.noScriptAsReact()}</div>
        <div id={this.props.scriptId || "react-google-tag-manager-gtm"}>
          {gtm.scriptAsReact()}
        </div>
      </div>
    ) : null;
  }
}

GoogleTagManager.propTypes = {
  gtmId: PropTypes.string.isRequired
};

export default GoogleTagManager;
