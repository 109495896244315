import React from "react";
import { Link } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import "./style.scss";

const Content = () => {
  return (
    <Container>
      <div className='content__wrapper button__wrapper text-center'>
        <p className='highlight'>
          Molecular heterogeneity of colorectal cancer is substantial and around
          50% of your patients with mCRC harbour a <i>RAS</i> or <i>BRAF</i>{" "}
          mutation<sup>1,2</sup>
        </p>
        <Link to='/about'>
          <Button>
            Deepen your understanding <span />
          </Button>
        </Link>
      </div>
      <hr />
      <div className='content__wrapper button__wrapper text-center'>
        <p className='highlight'>
          Patients with mCRC harbouring a <i>RAS</i> or <i>BRAF</i> mutation
          have poorer outcomes than those with wild-type tumours
          <sup>5&ndash;7</sup>
        </p>
        <Link to='/outcomes'>
          <Button>
            <div>
              Discover the danger of <i>RAS</i> and
              <br />
              <i>BRAF</i>&nbsp;&nbsp;mutations <span />
            </div>
          </Button>
        </Link>
      </div>
      <hr />
      <div className='content__wrapper button__wrapper text-center'>
        <p className='highlight'>
          Knowing tumour <i>RAS</i> and <i>BRAF</i> mutation status enables
          optimal diagnostic and prognostic decision-making, therefore, ESMO
          guidelines recommend that all patients with mCRC should be tested at
          diagnosis<sup>3</sup>
        </p>
        <Link to='/testing'>
          <Button>
            Knowing can make a difference
            <span />
          </Button>
        </Link>
      </div>
      <div className='content__wrapper'>
        <p className='acronym'>
          ESMO, European Society for Medical Oncology; mCRC, metastatic
          colorectal cancer.
        </p>
      </div>
    </Container>
  );
};
export default Content;
