export default [
  {
    title: "",
    content: `<p>Thank you for visiting Our Website. Ensuring data protection and data security for our customers and
users is a top priority for Us at Pierre Fabre.</p>
<p>This Privacy Policy explains what information is collected when you visit and use Our Website and
how this information is processed.</p>`
  },
  {
    title: "Who we are ?",
    content: `<p>This Website ("<b>Website</b>"), is operated by <b>Pierre Fabre Médicament</b> Société par Actions Simplifiées
with a capital of 49 974 975 Euros, it’s registered office is located at 45 place Abel Gance 92100
Boulogne Billancourt, registered at RCS NANTERRE SIREN 326 118 502, with intracommunity
VAT number FR 35 326 118 502, (hereinafter "<b>Pierre Fabre</b>", "<b>We</b>", "<b>Our</b>", "<b>Us</b>") who is the data
controller of any Personal Information which may be collected from you on the Website for the
purposes described below.</p>
<p>We invite you to read this Privacy Policy carefully to acknowledge the conditions of your Personal
Information processing when visiting our Website. Please note that it may be updated at any time by
Pierre Fabre. The date of the most recent revision will appear on this page. We suggest that you refer
back to it on a regular basis.</p>`
  },
  {
    title: "Information we collect about you",
    content: `<p>Depending on the services provided by our Website and used by you, your choices and the
configuration of your terminal (with respect in particular to cookies and other trackers), Personal
Information concerning you (your "<b>Personal Information</b>") collected and processed by <b>Pierre Fabre</b>
mainly include:</p>
<ul>
<li>
In using our Website, you may also voluntarily provide Personal Information which include:
your family name, first name, address, e-mail address, phone number, your preferences and
interests, and any personal details required to resolve any request or complaints.
</li>
<li>
When you visit our Website, our server automatically collects certain browser or device
generated information, including but not limited to: your domain; IP address; date, time and
duration of your visit; browser type; operating system; and page visits.
</li>
</ul>`
  },
  {
    title: "Purposes of your Personal Information processing",
    content: `<p>The following is an overview of our purposes for using your Personal Information and why we need
to process it.</p>
<table class="privacy-table">
<tr>
<th>Purposes of the processing</th>
<th>Why we need to process your Personal Information</th>   
</tr>
<tr>
<td>answer your questions sent via e-mail, contact forms and chats</td>
<td>pursuance of Pierre Fabre legitimate interests when the processing is required for the management of Our daily business activities</td>
</tr>
<tr>
<td>send you information about our news and events (newsletters, invitations and other publications)</td>
<td>your express consent</td>
</tr>
<tr>
<td>carry out statistics</td>
<td>pursuance of Pierre Fabre legitimate interests when the processing is required for the</td>
</tr>
<tr>
<td>notify to the relevant authorities any issue raised by the usage of our products</td>
<td>compliance with our legal obligations</td>
</tr>
</table>
<p>The Personal Information that is indispensable for us to fulfil the purposes that are described above is
marked with an asterisk on the various pages of the Website where it is collected. Should you choose
not fill in these mandatory fields, we may not be able to process your requests and/or to provide you
the requested products and services. Other Personal Information is purely optional and allow us to
know you better and to improve our communications and services accordingly. Further information
on purposes and legal basis for specific processing scenarios can be found below.</p>`
  },
  {
    title: "Personal Information Disclosure and Transfer",
    content: `<p>We are part of the global Pierre Fabre group, and from time to time it will be necessary to share your
Personal Information with our affiliated businesses when necessary for the purposes above listed.</p>
<p>We may also appoint third party service providers (who will operate under our instructions) to assist
us in providing information, products or services to you, in conducting and managing our business, or
in managing and improving Our Website. In such case those third parties may need to access your
Personal Information.</p>
<p>If your Personal Information is transferred outside the EEA (European Economic Area) to other
Pierre Fabre group companies or to service providers, we will take steps to ensure that your Personal
Information receives the same level of protection as if it remains within the EEA, including by
entering into Data Transfer Agreements, using the EU Commission approved Standard Contractual
Clauses as they may be updated or revised, or by relying on certification schemes such as the EU - US
Privacy Shield or other transfer mechanisms such adequacy decisions where applicable, Binding
Corporate Rules or EU Data Protection Authorities approved Agreements. For transfers to other
Pierre group outside the EEA, we have put in place and intragroup Data Transfer Agreement based on
the EU Commission approved Standard Contractual Clauses as they may be updated or revised, which
protect Personal Information transferred between Pierre Fabre entities.</p>
<p>You have a right to obtain details of the transfer mechanism under which your Personal Information is
transferred outside of the EEA by contacting dpo@pierre-fabre.com</p>
<p>We may share your Personal Information with third parties in connection with potential or actual sale
of our company or any of our assets, or those of any affiliated company, in which case Personal
Information held by us about our users may be one of the transferred assets.</p>
<p>We will also respond to requests for information where required by to do so by law, or when such
disclosure is required to protect our rights and/or comply with a judicial proceeding, court order,
request from a regulator or any other legal process served on us.</p>`
  },
  {
    title: "Emails",
    content: `<p>Where lawful to do so, and subject to your consent where required, we may communicate with you by
email to tell you about our products and services. If you wish to opt-out of receiving marketing
communications, please use the 'unsubscribe' link provided in our emails ,or otherwise contact us
directly and we will stop sending you communications.</p>`
  },
  {
    title: "Retention of your Personal Information",
    content: `<p>As a general rule, we keep your Personal Information only as long as required to fulfil the purposes
for which it was collected.</p>
<p>We retain your Personal Information for the time strictly necessary to manage the relationship with
you (for instance when you open an account, you purchase a product from our web site etc.).</p>
<p>However, we keep your Personal Information for marketing purposes for three years after the last
interaction between us when you are not a customer and as long as we remain in contact with you
through our promotional activities when you are a customer, unless you object when you receive one
of Our marketing messages.</p>
<p>IP addresses collected for security purposes will not be stored for longer than 10 days.</p>
<p>Finally, the connection logs that are collected, subject to your consent, using cookies and other
trackers implemented on our Site, shall be kept in accordance with applicable laws and regulations for
a period of time that does not exceed thirteen (13) months. For more details, see "Cookies" Section
below policy.</p>
<p>We may have to keep your Personal Information in an archive mode beyond the term indicated above
to comply with our legal obligations or where required by the applicable statute of limitation, to
evidence a right or a contract (e.g. all transactional information are kept for the term of the contract
and 5 years after the end of the contract).</p>
<p>When your Personal Information are no more necessary for the purposes or archives responding to our
legal obligations or statute of limitation, we ensure that they are either completely deleted or
anonymized.</p>`
  },
  {
    title: "External Links",
    content: `<p>The Website may contain links to third party sites. Since Pierre Fabre does not control nor is
responsible for the privacy practices of those websites, we encourage you to review the privacy
policies of these third party sites. This policy applies solely to Personal Information collected by our
Websites or in the course of our business activities.</p>`
  },
  {
    title: "Your rights",
    content: `<p>Subject to applicable law, you may have some or all of the following rights in respect of your
Personal Information:</p>
<ul>
<li>to obtain a copy of your Personal Information together with information about how and on
what basis that Personal Information is processed;</li>
<li>to rectify inaccurate Personal Information (including the right to have incomplete personal
information completed);</li>
<li>to erase your Personal Information (in limited circumstances, where it is no longer necessary
in relation to the purposes for which it was collected or processed);</li>
<li>to restrict the processing of your Personal Information where:
    <ul>
        <li>the accuracy of the Personal Information is contested;</li>
        <li>the processing is unlawful but you object to the erasure of the Personal Information;</li>
        <li>we no longer require the Personal Information but it is still required for the
establishment, exercise or defense of a legal claim;</li>
    </ul>
</li>
<li>to request the portability of your Personal Information in machine-readable format to a third
party (or to you) when we justify our processing on the basis of your consent or the
performance of a contract with you;</li>
<li>to give general or specific instructions on how your Personal Information should be processed
and may be used, after your death.</li>
<li>To oppose at any time to be contacted by phone for direct marketing purposes, by registering
free of charge on the website: <a href="www.bloctel.gouv.fr">www.bloctel.gouv.fr</a>;</li>
<li>to withdraw your consent to our processing of your Personal Information (where that
processing is based on your consent);</li>
<li>to object to a processing for marketing purpose; and</li>
<li>to obtain, or see a copy of the appropriate safeguards under which your Personal Information
is transferred to a third country or international organisation.</li>
</ul>
<p><b>In addition to the above rights, you have the right to object to any processing of your
Personal Information justified by Pierre Fabre legitimate interest, including profiling (as
opposed to your consent, or to perform a contract with you). You also have the right to
object to any processing of your Personal Information for direct marketing purposes,
including profiling.</b></p>
<p>You also have the right to lodge a complaint with your local supervisory authority for data
protection, for example the ICO in the UK, the CNIL in France or the AEPD in Spain.</p>
<p>To exercise these rights, please contact us using the details given below. Please note that we may
request proof of identity, and we reserve the right to charge a fee where permitted by law, for instance
if your request is manifestly unfounded or excessive. We will endeavour to respond to your request
within all applicable timeframes.</p>`
  },
  {
    title: "Cookies",
    content: `<p>A  cookie is a small file that can be placed on your device's hard drive or on a website server. We use
cookies to obtain some of the automatically collected information described above. You do not have
to accept a cookie, and can choose which cookie to accept by changing the parameters in your
browser so that it will not accept cookies. For more information about cookies including how to set
your internet browser to reject cookies or to better control the installation of cookies in your device,
please go to <a href="https://www.cookiebot.com/">https://www.cookiebot.com/</a></p>`
  },
  {
    title: "Types of Cookies We use",
    content: `<p>Different types of cookies are used for different purposes on our Website, these are known as: strictly
necessary cookies, performance cookies, marketing cookies, and functionality cookies. Some cookies
may be provided by an external third party to provide additional functionality to our Website and
these are included below.</p>
<ul>
<li><b>Strictly necessary cookies:</b> these are cookies that are essential to fulfil an action requested by
you, such as identifying you as being logged in. If you prevent these cookies by adjusting
your browser settings we cannot guarantee how our Website will perform during your visit.</li>
<li><b>Performance cookies:</b> these are cookies used to improve our Website, for example for
analytics that let us see how our Website is being used and where to make improvements.
These cookies are used to collect information about how visitors use our Website. The
information is collected in an anonymous form and includes the number of visitors, where
visitors have come from to the Websites and the pages they visited.</li>
<li><b>Marketing cookies:</b> these cookies are used to deliver adverts relevant to an identified
machine or other device (not a named or otherwise identifiable person) which are tailored to
interests associated with the website activity tied to that machine or device (e.g., clicked ad
banners, visited subpages, search queries asked). These cookies are also used to limit the
number of times a user sees an advertisement as well as to help measure the effectiveness of
an advertising campaign. They may also remember that the Website has been visited from a
device and share that information with marketing organizations. The marketing cookies on
our Website are operated by third parties with our permission. Marketing cookies are used to
monitor from which advertising source a user was directed towards our website so that we
know whether it is worth us investing in that particular advertising source.</li>
<li><b>Functionality cookies:</b> these cookies enhance the performance and functionality of our
Website, often as a result of something you’re doing as a user. For instance, we may
personalize our content for you or remember your preferences.</li>
<li><b>Third Party Social Plugin Sharing Cookies in Connection with Social Plugins:</b>
Embedding Social Plugins generally has the plugins’ providers store cookies. These cookies
allow our users to share information with the respective social network but also allow the
network operator to identify the user and store information about the usage of the Website by
the user in his/her profile.</li>
</ul>
<p>You may find below the list of Cookies We use on our Website</p>
<div id="ot-sdk-cookie-policy"></div>`
  },
  {
    title: "Managing and Deleting Cookies",
    content: `<p>To allow you to control the usage of cookies on Our Website, we have implemented a tool available
when you access Our Website which invites to configure the different cookies that you consent to
have installed. Please see <a href="https://www.cookiebot.com/">https://www.cookiebot.com/</a> for further information.</p>
<p>If you reject cookies, we will not set any further cookies on your device, except the “functional”
cookies detailed above (including, unavoidably, a cookie to remember that you do not want any
cookies set when you visit the Website).</p>
<p>You should however note that if you choose to remove or reject certain cookies, it will affect certain
features or services on our Website.</p>`
  },
  {
    title: "Update of the Privacy Policy",
    content: `<p>We may change this Privacy Policy from time to time in order to reflect changes in the law and/or our
privacy practices.</p>`
  },
  {
    title: "Contact information",
    content: `<p>If you have any questions in relation to this Privacy Policy or you wish to exercise any of your rights,
please contact our data protection officer, <a href="mailto:dpofr@pierre-fabre.com">dpofr@pierre-fabre.com</a></p>`
  },
  {
    title: "",
    content: `<p><b>Last modified: 16/01/2023</b></p>`
  },
];
